// This is our base Sentry config that is used on all runtimes: client, edge and server.

import type { init } from '@sentry/nextjs';

const sentryConfig: Parameters<typeof init>[0] = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: 'live.platform.carbonfact.com', // Placed in-line at build time, see package.json prebuild:prod script
  release:
    process.env.NODE_ENV === 'production'
      ? `${'82572d1e1c41a26e33d02a383220182cf2555446'}` // Replaced in-line at build time, see package.json prebuild:prod script
      : `platform-${process.env.NODE_ENV}-${process.env.USER}`,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.6 : 1.0,

  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,

  beforeSend: (event) => {
    // Make sure to scrub any PII before sending events.
    if (event.user?.ip) {
      event.user.ip = undefined;
    }

    if (event.user?.email) {
      event.user.email = event.user.email.replace(/^.+@/, 'anonymized@');
    }

    return event;
  },

  tracePropagationTargets: [
    /^https:\/\/(stable|live|demo)\.api\.carbonfact\.com\//,
  ],
};

export default sentryConfig;
