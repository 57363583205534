// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"82572d1e1c41a26e33d02a383220182cf2555446"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import sentryConfig from 'sentry.config';

Sentry.init({
  ...sentryConfig,
  // Replay is only valid on the client.
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    posthog.sentryIntegration({
      prefix: 'https://carbonfact-eu.sentry.io/',
      organization: 'carbonfact-eu',
      projectId: 4507572532543568, // 'platform' project
    }),
  ],
});
